import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const Home = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace('https://www.eatbaryo.com/');
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="dhiwise-navigation">
      <h1>Homepage</h1>
      <p className="headline">
      Domain is being migrated to eatbaryo
        <a href="https://www.eatbaryo.com/">Baryo</a>. 
      </p>
    </div>
  );
};
export default Home;